import React from 'react';
import { NavLink, Link } from "react-router-dom";
import Header from './Header';
import Navbar from '../Navbar';   
import About from './About'
import Resume from './Resume';
import Board from './Board';
import Articals from './Articals';
import Footer from '../Footer';

const Home = () => {
  return (
    <>
      <Header/>
      <Navbar/>
      <About/>
      <Board/>
      <Resume/>
      <Articals/>
      <Footer/>
    </>
  )
}

export default Home
