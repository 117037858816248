import React from 'react';
import './johndoe.css';
import './themify-icons.css'
import './Home.css'
import myPdf from "../../files/SharunShetty.pdf"

const Header = () => {
    return (
        <header className="header" style={{ textAlign: 'left'}}>
            <div className="container">
                <ul className="social-icons pt-3">
                    <li className="social-item"><a className="social-link text-light" href="#" style={{ textDecoration:'none'}}><i className="ti-facebook" aria-hidden="true"></i></a></li>
                    <li className="social-item"><a className="social-link text-light" href="https://www.linkedin.com/in/sharunshetty" style={{ textDecoration:'none'}}><i className="ti-linkedin" aria-hidden="true" ></i></a></li>
                    <li className="social-item" ><a className="social-link text-light" href="#" style={{ textDecoration:'none'}}><i className="ti-twitter" aria-hidden="true"></i></a></li>
                    <li className="social-item"><a className="social-link text-light" href="#" style={{ textDecoration:'none'}}><i className="ti-instagram" aria-hidden="true"></i></a></li>
                    <li className="social-item"><a className="social-link text-light" href="https://github.com/SharunMundkur" style={{ textDecoration:'none'}}><i className="ti-github" aria-hidden="true"></i></a></li>
                </ul>  
                <div className="header-content">
                    <h4 className="header-subtitle" >Hello, I am</h4>
                    <h1 className="header-title">Sharun Shetty</h1>
                    <h6 className="header-mono" >Full Stack Web Developer</h6>
                    <a href={myPdf} download="SharunShetty.pdf">
                        <button className="button btn-primary btn-rounded"><i className="ti-printer pr-2"></i>Print Resume</button>
                    </a>
                </div>
            </div>
        </header>
    );
}

export default Header;
