import React from 'react'

const Resume = () => {
  return (
    <section className="section" id="resume">
        <div className="container" style={{ textAlign: 'left' }}>
            <h2 className="mb-5"><span className="text-danger">My</span> Resume</h2>
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <div className="card">
                       <div className="card-header">
                            <div className="mt-2">
                                <h4>Expertise</h4>
                                <span className="line"></span>  
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="title text-danger">Feb 2023 - present</h6>
                            <p>Junior Software Engineer</p>
                            <p className="subtitle">I specialize in leveraging cutting-edge technologies such as React, ASP.NET Web API, Azure, and PostgreSQL to deliver high-performance solutions. My expertise spans across modern web development, cloud integration, and database management, ensuring scalable and efficient applications.</p>
                            <hr/>
                            <h6 className="title text-danger">June 2022 - Oct 2022</h6>
                            <p>Software Engineering Intern</p>
                            <p className="subtitle">Contributed to the development of an E-Bidding application using ASP.NET MVC, handling the design, coding, and testing of key modules. Enhanced user experience by implementing responsive, intuitive interfaces.</p>
                            <hr/>
                            <h6 className="title text-danger">Sep 2021 - 1 Month</h6>
                            <p>Data Science Intern</p>
                            <p className="subtitle">Conducted in-depth analysis of large datasets containing Nifty and Sensex prices to develop predictive models for forecasting future market trends. Utilized Python and Microsoft Excel for data processing and model building.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4">
                    <div className="card">
                       <div className="card-header">
                            <div className="mt-2">
                                <h4>Education</h4>
                                <span className="line"></span>  
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="title text-danger">Aug 2019 - Jun 2023</h6>
                            <p>B.E Computer Engineering</p>
                            <p className="subtitle">Graduated with a Bachelor's in Computer Science from Sri Devi Institute of Technology, Mangalore, in August 2023, building a solid foundation in software development and problem-solving.</p>
                            <hr/>
                            <h6 className="title text-danger">June 2017 - April 2019</h6>
                            <p>Higher Secondary</p>
                            <p className="subtitle">Completed Higher Secondary in Science at Sri Lakshmi Janardhan PU College, Belman, from June 2017 to April 2019.</p>
                            <hr/>
                            <h6 className="title text-danger">June 2007 - April 2017</h6>
                            <p>School</p>
                            <p className="subtitle">Completed Secondary education under the CBSE board at Sri Lakshmi Janardhan International School, Belman, from June 2007 to April 2017.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                       <div className="card-header">
                            <div className="pull-left">
                                <h4 className="mt-2">Skills</h4>
                                <span className="line"></span>  
                            </div>
                        </div>
                        <div className="card-body pb-2">
                           <h6>React</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "97%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>JavaScript</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "85%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>C#</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "90%"}} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>ASP.net Web API</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "80%"}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>Python</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "90%"}} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>SQL</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "90%"}} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                       <div className="card-header">
                            <div className="pull-left">
                                <h4 className="mt-2">Languages</h4>
                                <span className="line"></span>  
                            </div>
                        </div>
                        <div className="card-body pb-2">
                           <h6>English</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "75%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>Hindi</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "45%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>Kannada</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "67%"}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                            <h6>Tulu</h6>
                            <div className="progress mb-3" style={{height: "5px"}}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{width: "81%"}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Resume
