import React from "react";
import logo from "./logo.svg";
import "./App.css";
//import { Route, Routes,  Link } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Navbar from "./Components/Navbar";
import About from "./Components/Pages/About";
import Home from "./Components/Pages/Home";
import Contact from "./Components/Pages/Contact";
import Articals from "./Components/Pages/Articals"; // Fixed typo: 'Articals' to 'Articles'

function App() {
  return (
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav> */}

        <Routes>
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
  );
}

export default App;
